<template>
    <div class="mainboxs">
        <div class="abroad_til">
            <div class="abroad_til_dec">
                <div class="elp">{{ countryDetail.name }}留学 </div>
                <div class="elp">{{ countryDetail.topIntroduce }} </div>
            </div>
        </div>
        <div class="abroad_til_introduce">
            <div class="abroad_introduce_til">
                <img style="margin-right: 20px;" src="~@/assets/img/TechnicalCollege/63.png" alt="">
                {{ countryDetail.name }}留学
                <img style="margin-left: 20px;" src="~@/assets/img/TechnicalCollege/62.png" alt="">
            </div>
            <div class="line"></div>
            <div class="conent" v-html="countryDetail.introduce"></div>
        </div>
        <div class="school">
            <div class="s_bg">
                <img style="width: 100%;" src="~@/assets/img/TechnicalCollege/64.png" alt="">
            </div>
            <div style="height: 1px;"></div>
            <div class="school_recommend">
                <div class="school_recommend_til">
                    <img style="margin-right: 20px;" src="~@/assets/img/TechnicalCollege/66.png" alt="">
                    {{ countryDetail.name }}留学学校推荐
                    <img style="margin-right: 20px;" src="~@/assets/img/TechnicalCollege/67.png" alt="">
                </div>
                <div class="school_recommend_con">
                    <div @click="goNext(`/gxGathers/index?id=${item.id}`)" v-for="(item, index) in majorList"
                        :key="index" class="item">
                        <div class="logo">
                            <img :src="item.schoolBadge" alt="">
                        </div>
                        <div>
                            <div class="s_name elp">{{ item.schoolName }}</div>
                            <div class="school_address">
                                <img style="width: 10px;height: auto;margin-right: 5px;"
                                    src="~@/assets/img/TechnicalCollege/81.png" alt="">
                                {{ item.areaProvinceName }} {{ item.areaCityName }}
                            </div>
                            <div class="bq">
                                <div v-for="(items, indexs) in item.studyModeNameList.split(',')" :key="indexs">{{ items
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="detail_right">
            <div class="detail_right_til">
                <img src="~@/assets/img/TechnicalCollege/12.png" alt="">
                常见问题
            </div>
            <div class="question_list">
                <div v-for="(item, index) in qusetionList" :key="index" class="question_item">
                    <div class="q_ask">
                        <div>Q</div>
                        <div>{{ item.title }}</div>
                    </div>
                    <div class="q_answer">
                        <div>A</div>
                        <div v-html="item.answer"> </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div @click="goNext('/gxGather/abroad/question')" class="more_question">查看更多问题 <img
                    style="width: 12px;height: 12px;margin-left: 5px;" src="~@/assets/img/TechnicalCollege/24.png"
                    alt=""> </div>

        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            countryId: '',
            projectType: '',
            qusetionList: [],
            majorList: [],
            countryDetail: {},
            search: {
                pageSize: 5,
                pageNum: 1,
                type: '2',
                countryId: ''
            },
        };
    },
    created() {
        this.projectType = localStorage.getItem('projectType');
        this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
        // this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.search.countryId = this.$route.query.countryId;
        this.countryId = this.$route.query.countryId;


        know.getEnrollStudyCountry({ id: this.countryId }).then((res) => {
            this.countryDetail = res.data
        });
        this.getStudyAbroadSchoolPage()
        this.getEnrollAgentProblemPage()

    },
    mounted() {

    },
    methods: {
        chooseMajor(i) {
            this.whichNews = i
        },
        getEnrollAgentProblemPage() {
            know.getEnrollAgentProblemPage({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
        getStudyAbroadSchoolPage() {
            know.getStudyCountrySchoolPage({
                pageNum: 1,
                pageSize: 6,
                projectType: this.projectType,
                projectCategoryId: this.search.projectCategoryId,
                countryId: this.countryId
            })
                .then((res) => {
                    this.majorList = res.rows
                });
        },

        goPointLink() {
            if (this.link) {
                window.open(this.link, '_blank');

            }
        }

    },
};
</script>

<style lang="less" scoped>
.mainboxs {
    background: #F5F7F9;
    margin-bottom: 20px;

    .abroad_til {
        width: 100%;
        height: 300px;
        background: url('~@/assets/img/TechnicalCollege/61.png');
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        .abroad_til_dec {
            width: 1050px;
            margin: 0 auto;

            >div:nth-child(1) {
                width: 300px;

                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                font-weight: normal;
                font-size: 40px;
                color: #FFFFFF;
            }

            >div:nth-child(2) {
                width: 669px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 24px;
                color: #FFFFFF;
                margin-top: 10px;
            }

            >div:nth-child(3) {
                width: 669px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 24px;
                color: #FCC48A;
                margin-top: 10px;

            }
        }

    }

    .abroad_til_introduce {
        width: 100%;
        background: #FFFFFF;
        padding: 60px;

        .abroad_introduce_til {
            // width: 1200px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 30px;
            color: #444444;
            font-weight: bold;

            img {
                width: 83px;
                height: 19px;
            }
        }

        .line {
            width: 32px;
            height: 4px;
            background: #3D6CC8;
            border-radius: 8px 8px 8px 8px;
            margin: 0 auto;
            margin-top: 10px;
        }

        .conent {
            width: 1200px;
            padding: 30px;
            background: #F5F7F9;
            border-radius: 10px 10px 10px 10px;
            margin: 0 auto;
            margin-top: 18px;
            line-height: 1.5;
        }
    }

    .school {
        width: 100%;
        position: relative;
        min-height: 480px;

        .s_bg {
            position: absolute;
            width: 1920px;
            height: 366px;
            background: #2F3959;
            opacity: 0.9;
        }

        .school_recommend {
            position: relative;
            width: 1200px;
            height: 429px;
            background: #2F3959;
            border-radius: 0px 0px 0px 0px;
            background: url('~@/assets/img/TechnicalCollege/65.png');
            background-size: 100% 100%;
            margin: 0 auto;
            margin-top: 60px;
            padding: 35px;

            .school_recommend_til {
                display: flex;
                align-items: center;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                font-weight: normal;
                font-size: 30px;
                color: #AF937D;
                font-weight: bold;

                img {
                    width: 54px;
                    height: 26px;
                }

            }

            .school_recommend_con {
                width: 1130px;
                height: 300px;
                background: #FFFFFF;
                border-radius: 6px 6px 6px 6px;
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;


                .item {
                    display: flex;
                    width: 287px;
                    margin-top: 30px;
                    margin-left: 40px;
                    cursor: pointer;
                    margin-right: 40px;

                    .logo {
                        flex-shrink: 0;
                        width: 94px;
                        height: 94px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .school_address {
                        display: flex;
                        align-items: center;

                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 12px;
                        color: #666666;
                        margin-top: 8px;
                    }

                    .s_name {
                        width: 200px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                        font-weight: bold;
                        font-size: 18px;
                        color: #333333;
                    }

                    .bq {
                        display: flex;
                        margin-top: 10px;

                        >div {
                            padding: 2px 10px;
                            background: #FAF4EC;
                            border-radius: 3px 3px 3px 3px;
                            font-family: MicrosoftYaHei, MicrosoftYaHei;
                            font-weight: normal;
                            font-size: 12px;
                            color: #DF932F;
                            margin-right: 5px;
                        }
                    }
                }

            }



        }
    }

    .detail_right {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        margin: 0 auto;
        margin-top: 30px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 26px;
            color: #444444;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }

        .question_list {
            padding: 30px;
            padding-top: 0;

            .question_item {
                margin-top: 25px;

                .q_ask {
                    display: flex;
                    align-items: baseline;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #FECD61;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;

                    }

                    >div:nth-child(2) {
                        padding: 5px 20px;
                        background: #FECD61;
                        border-radius: 15px 15px 15px 15px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        margin-left: 10px;
                    }
                }

                .q_answer {
                    display: flex;
                    align-items: baseline;
                    margin-top: 12px;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #E3E3E3;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;
                    }

                    >div:nth-child(2) {
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #555555;
                        margin-left: 10px;
                    }
                }
            }
        }

        .line {
            width: 1140px;
            height: 0px;
            border: 1px solid #F2F2F2;
            margin: 0 auto;
            margin-top: 20px;

        }

        .more_question {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 68px;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
            color: #1061FF;
            cursor: pointer;
        }
    }
}



img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>